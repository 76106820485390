import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './Cart.css'; // Assuming you have a CSS file for additional styling

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate(); // Initialize the navigate hook

  // Load cart items from localStorage when component mounts
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
  }, []);

  // Handle removing item from cart
  const handleDelete = (indexToDelete) => {
    const updatedCart = cartItems.filter((_, index) => index !== indexToDelete);

    // Update state and localStorage
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  // Handle navigation to the Checkout page
  const handleCheckout = () => {
    navigate('/checkoutForm', { state: { cartItems } }); // Pass cart items to CheckoutForm page
  };

  return (
    <div className="container mrg-top">
      <h1>Shopping Cart</h1>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <>
          {cartItems.map((item, index) => (
            <div key={index} className="row cart-item mb-4">
              {/* Left Column: Product Image */}
              <div className="col-sm-6">
                <img src={item.product.image} alt={item.product.name} className="product-image img-fluid" />
              </div>

              {/* Right Column: Product Details */}
              <div className="col-sm-6 cart-details">
                <h2>{item.product.name}</h2>
                <p>{item.product.description}</p>
                <p>Price per Item: {item.product.price}</p>
                <p>Quantity: {item.quantity}</p>
                <p>Total Price: ${item.totalPrice}</p>
                {/* Delete Button */}
                <button className="btn btn-danger mt-2" onClick={() => handleDelete(index)}>
                  Delete
                </button>
              </div>
            </div>
          ))}
          
          {/* Order Now Button */}
          <button className="btn btn-success mt-3" onClick={handleCheckout}>
            Order Now
          </button>
        </>
      )}
    </div>
  );
};

export default Cart;
