import './App.css';
import Navbar from './Component/Navbar/Index';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import HeroSection from './Component/HeroSection/Index';
import NewStock from './Component/NewStock/Index';
import OurStory from './Component/OurStory/Index';
import CustomersSay from './Component/CustomersSay/Index';
import GiftCard from './Component/GiftCard/Index';
import Footer from './Component/Footer/Index';
import ProductDetails from './Component/ProductDetails/Index';
import Home from './Component/Pages/Home/Index';
import Cart from './Component/Pages/Cart/Index';
// import Products from './Component/Pages/Home/Products/Index';
import CheckoutForm from './Component/CheckoutForm/Index';
import Blogs from './Component/Pages/Blogs/Index';
import BlogForm from './Component/BlogForm/Index';
import BlogsList from './Component/BlogList/Index';
import BlogDisplay from './Component/BlogList/Index';
import BlogDetail from './Component/BlogDetail/Index';
import BlogDetails from './Component/BlogDetail/Index';
import BlogUploadForm from './Component/BlogForm/Index';
import ContactUs from './Component/Pages/ContactUs/Index';
import ProductHome from './Component/Product/Index';
import Product from './Component/Product/Index';
import AboutUs from './Component/Pages/AboutUs/Index';
import Blog1 from './Component/Blogs/Blog1/Index';
import Blog2 from './Component/Blogs/Blog2/Index';
import Error404 from './Component/Pages/404/Index';
import PrivacyPolicy from './Component/PrivacyPolicy/Index';
import TersCondition from './Component/TermCondition/Index';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/ProductDetails/:id" element={<ProductDetails />} />
        <Route path="/" element={<Home />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/CheckoutForm" element={<CheckoutForm />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/products" element={<Product />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/blogUploadForm" element={<BlogUploadForm />} />
        <Route path="/BlogDisplay" element={<BlogDisplay />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/blog1" element={<Blog1 />} />
        <Route path="/blog2" element={<Blog2 />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TersCondition" element={< TersCondition/>} />
        

  
        <Route path="*" element={<Error404 />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
