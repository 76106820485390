// src/components/BlogList.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const BlogList = () => {
  // Blog data array
  const blogs = [
    {
      id: 1,
      title: "A Detailed Guide on Choosing the Right Boots: How to Do It Right",
      image: "path/to/image1.jpg", // Replace with your image path
      link: "/blog1", // Update with the correct route for this blog
    },
    {
      id: 2,
      title: "Another Blog Title",
      image: "path/to/image2.jpg",
      link: "/blog2", // Update with the correct route for this blog
    },
    // Add more blog objects here
  ];

  const navigate = useNavigate();

  return (
    <div className="blog-list">
      {blogs.map(blog => (
        <div 
          key={blog.id} 
          className="blog-card" 
          onClick={() => navigate(blog.link)} // Navigate on click
          style={{ cursor: 'pointer', margin: '20px', width: '300px' }}
        >
          <img src={blog.image} alt={blog.title} style={{ width: '100%', height: 'auto' }} />
          <h2>{blog.title}</h2>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
