import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import './Product.css';

const Product = () => {
  const navigate = useNavigate();

  const products = [
    {
      id: 1,
      image: 'images/img7.jpg',
      rating: 4.5,
      name: 'TEsting',
      price: '$99.99',
      description: 'This is a detailed description of Product Name.',
    },
    {
      id: 2,
      image: 'Images/img6.jpg',
      rating: 4.3,
      name: 'Product Alpha',
      price: '$89.99',
      description: 'This is a detailed description of Product Alpha.',
    },
    {
      id: 3,
      image: 'Images/img5.jpg',
      rating: 4.0,
      name: 'Product Beta',
      price: '$79.99',
      description: 'This is a detailed description of Product Beta.',
    },
    {
      id: 4,
      image: 'Images/img4.jpg',
      rating: 4.7,
      name: 'Product Gamma',
      price: '$109.99',
      description: 'This is a detailed description of Product Gamma.',
    },
    {
      id: 5,
      image: 'Images/img3.jpg',
      rating: 4.6,
      name: 'Product Delta',
      price: '$95.99',
      description: 'This is a detailed description of Product Delta.',
    },
    {
      id: 6,
      image: 'Images/img2.jpg',
      rating: 4.8,
      name: 'Product Epsilon',
      price: '$119.99',
      description: 'This is a detailed description of Product Epsilon.',
    },
  ];
  

  const handleProductClick = (product) => {
    navigate(`/ProductDetails/${product.id}`, { state: { product } });
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} className={i < Math.floor(rating) ? 'star filled' : 'star'}>
          ★
        </span>
      );
    }
    return stars;
  };

  return (
  <div className="container mrg-top">
    <div className="row">
    <h2 className='display-4 text-center mx-4'>Products</h2>
    <div className="product-container">
      {products.map((product, index) => (
        <div key={index} className="product-card" onClick={() => handleProductClick(product)}>
          <img src={product.image} alt={product.name} className="product-image" />
          <div className="product-details">
            <h3>{product.name}</h3>
            <div className="product-rating">{renderStars(product.rating)}</div>
            <p className="product-price">{product.price}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
  </div>
  );
};

export default Product;
