import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="section-nav">
      <div className={`bg-light navbar-wrapper custom-navbar ${isScrolled ? 'scrolled' : ''}`}>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="navbar-container">
              {/* Toggler button on the left */}
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleToggle}
                aria-controls="navbarTogglerDemo01"
                aria-expanded={isOpen ? 'true' : 'false'}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <NavLink className="navbar-brand fs-1" to="/">
                BABYTECH
              </NavLink>
            </div>

            {/* Navbar links */}
            <div className={`navbar-content collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarTogglerDemo01">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink exact className="nav-link" to="/" activeClassName="active-link">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/blogs" activeClassName="active-link">Blog</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/aboutUs" activeClassName="active-link">About Us</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link last" to="/contactUs" activeClassName="active-link">Contact</NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
