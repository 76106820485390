import React from 'react'
import WhoWeAre from '../../WhoWeAre/Index'
import WhyChoose from '../../WhyChoose/Index'

function AboutUs() {
  return (
    <div className='mrg-top'>
    <h1 className='text-center mrg-top'>About Us</h1>
  

<WhoWeAre/>
<WhyChoose/>
    </div>
  )
}

export default AboutUs
