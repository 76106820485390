import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ProductDetails.css';

const ProductDetails = () => {
  const location = useLocation();
  const { product } = location.state || {}; // Ensure that product exists
  const [quantity, setQuantity] = useState(1);

  // If product is undefined, don't proceed
  if (!product) {
    return <p>Product details are not available.</p>;
  }

  // Calculate the total price based on the product's price and quantity
  const priceWithoutDollar = parseFloat(product.price.replace('$', ''));
  const totalPrice = (priceWithoutDollar * quantity).toFixed(2);

  // Handle quantity change
  const handleQuantityChange = (e) => {
    setQuantity(Math.max(1, Number(e.target.value))); // Prevent negative or zero quantity
  };

  return (
    <div className="container mrg-top">
      <div className="row m-5">
        <div className="col-md-6">
          <img src={product.image} alt={product.name} className="product-image" />
        </div>
        <div className="col-md-6">
          <h1>{product.name}</h1>
          <p>{product.description}</p>
          <p className="product-price">Price: {product.price}</p>
          <div className="product-rating">Rating: {product.rating} ★</div>
          <div className="quantity-selector my-3">
            <label htmlFor="quantity" className="me-2">Quantity:</label>
            <input 
              type="number" 
              id="quantity" 
              value={quantity} 
              min="1" 
              onChange={handleQuantityChange}
              className="quantity-input" 
            />
          </div>
          <p>Total Price: ${totalPrice}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
