import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; 

const firebaseConfig = {
  apiKey: "AIzaSyA-NUeGUfPWb7QxwJCkNHMy-cTk5M0Y4Hs",
  authDomain: "techbabylabs.firebaseapp.com",
  projectId: "techbabylabs",
  storageBucket: "techbabylabs.appspot.com",
  messagingSenderId: "912943518914",
  appId: "1:912943518914:web:5b778b391294336219975b",
  measurementId: "G-2RXHWNMGBL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { db, storage };
