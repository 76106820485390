import React from 'react';
import './Footer.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      {/* Navigation Links */}
      <nav className="footer-nav">
        <NavLink to="/home" activeClassName="active-link">Home</NavLink>
        <NavLink to="/aboutUs" activeClassName="active-link">About</NavLink>
        <NavLink to="/blogs" activeClassName="active-link">Blog Post</NavLink>
        <NavLink to="/PrivacyPolicy" activeClassName="active-link">Privay Policy</NavLink>
        <NavLink to="/TersCondition" activeClassName="active-link">Terms & Condition</NavLink>

      </nav>
      
      {/* Subscribe Section */}
      <div className="subscribe">
        <input type="email" placeholder="Enter your email" className="subscribe-input" />
        <button className="subscribe-btn">Subscribe</button>
      </div>

      {/* Contact Information Section */}
      <div className="contact-info">
        <p><strong>Address:</strong> 123 Tech Street, Islamabad, Pakistan</p>
        <p><strong>Email:</strong> contact@techbabylabs.com</p>
        <p><strong>Phone:</strong> +92 123 456 7890</p>
      </div>

      {/* Social Media Icons */}
      <div className="social-icons">
        <a href="https://random-link-facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://random-link-twitter.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://random-link-instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://random-link-linkedin.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>

      {/* Copyright Information */}
      <div>
        <p className='text-center my-5'>© 2024 techbabylabs.com. Powered by techbabylabs.com.</p>
      </div>
    </footer>
  );
};

export default Footer;
