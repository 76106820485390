import React from 'react'
import BlogsList from '../../BlogsList/Index'


function Blog() {
  return (
    <div>
    
          <h1 className='display-4 mrg-top text-center'>Blogs</h1>
<BlogsList/>
    </div>
  )
}

export default Blog
