import React from 'react';
import './OurClients.css'; // Import the CSS file for styling

const testimonials = [
  {
    name: 'Liam Anderson',
    username: '@liamanderson',
    image: 'https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png',
    review: 'Your suggestions and guidance were so much helpful. I love reading your blogs',
    rating: 4,
  },
  {
    name: 'Ethan Roberts',
    username: '@ethanroberts',
    image: 'https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png',
    review: 'Amazing. There was so much stuff that I was taking so lightly. But your blogs made me rethink on those',
    rating: 5,
  },
  {
    name: 'Mason Cooper',
    username: '@masoncooper',
    image: 'https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png',
    review: 'Your blogs really make a difference in changing shopping mindset. Thanks man',
    rating: 4,
  },
  {
    name: 'Lucas Turner',
    username: '@lucasturner',
    image: 'https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png',
    review: 'Your blogs really make a difference in changing shopping mindset. Thanks man',
    rating: 4,
  },
];


const OurClients = () => {
  return (
    <section id="testimonials">
      <div className="testimonial-heading"></div>
      <div className="testimonial-box-container">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-box" key={index}>
            <div className="box-top">
              <div className="profile">
                <div className="profile-img">
                  <img src={testimonial.image} alt={testimonial.name} />
                </div>
                <div className="name-user">
                  <strong>{testimonial.name}</strong>
                  <span>{testimonial.username}</span>
                </div>
              </div>
              
            </div>
            <div className="client-comment">
              <p>{testimonial.review}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurClients;
