import React from 'react'
import './CustomerSay.css'
import OurClients from '../OurClients/Index'
function CustomersSay() {
  return (
    <div>
      <h2 className='text-center h2-customer'>Reviews</h2>
      <OurClients/>
    </div>
  )
}

export default CustomersSay
