import React, { useState } from 'react';
import './OurStory.css'; 
import { Link } from 'react-router-dom';

function OurStory() {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (

<div className="section-story">
<div className="container">
      <div className="row">
      <h2 className='text-center my-5'>WHO WE ARE</h2>
        <div className="col-sm-6">
          <div className="our-story-container">
            <div className="our-story-image">
              <img className='img-our' src="Images/img8.jpg" alt="Our Story" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="our-story-text">
            
            <p className='p-our'>
          
              I am a fashion designer as well as owner of clothing 
              stores, and I love style and creativity passionately. For this reason, my whole career 
              has been dedicated towards ensuring that not only do people look their best but 
           
              also can feel confident in what they put on. My journey in fashion has given me an 
              understanding of how the proper outfit or accessory can transform an entire look.
             
            </p>
            <p className='p-our'>
             
                  I have a clothes shop, so I ensure that I bring a collection of pieces that represent 
                  modern trends but also that wonderful timeless elegance. My service does not stop 
                  at design; here, I am ready to help guide you through choosing the best products...
                 
                  I have a clothes shop, so I ensure that I bring a collection of pieces that represent 
                  modern trends but also that wonderful timeless elegance. My service does not stop 
                  at design; here, I am ready to help guide you through choosing the best products 
                  for your personal taste and lifestyle. In finding the perfect outfit or even advice on 
                  the bare essentials of fashion, I'm excited and eager to empower others in 
                  discovering what makes them feel confident and unique.
             
            </p>

<div className='my-5'>
<Link to="/blogs" className='btn-shop '>Read Blogs</Link>

</div>        
  </div>
        </div>
      </div>
    </div>
</div>
  );
}

export default OurStory;
