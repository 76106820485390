import React, { useState } from 'react';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    // Add more fields here as needed (e.g., phone, subject)
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Form submitted:', formData);
  };

  return (
   <>
    <h1 className='text-center mrg-top'>Contact Us</h1>
   


     <div className="container d-flex justify-content-center ">

      <div className="col-md-6 ">
        {/* <h2 className="text-center display-4">Contact Us</h2> */}
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3"> {/* Added spacing with mb-3 */}
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              name="name"
              required
            />
          </div>
          <div className="form-group mb-3"> {/* Added spacing with mb-3 */}
            <label htmlFor="email">Email address:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              name="email"
              required
            />
          </div>
          <div className="form-group mb-3"> {/* Added spacing with mb-3 */}
            <label htmlFor="message">Message:</label>
            <textarea
              className="form-control"
              id="message"
              rows="3"
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}
              name="message"
              required
            ></textarea>
          </div>
          {/* Add more input fields here */}
          <div className="form-group mb-3"> {/* Added spacing with mb-3 */}
            <label htmlFor="phone">Phone Number (Optional):</label>
            <input
              type="tel" // Specify phone number input type
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
              value={formData.phone || ''} // Handle optional field
              onChange={handleChange}
              name="phone"
            />
          </div>
         
          <button type="submit" className="btn-shop mt-3">
            Submit
          </button>
        </form>
      </div>
    </div>
   </>
  );
}

export default ContactUs;