import React from 'react';
import './GiftCard.css'; 
import { Link } from 'react-router-dom';

function GiftCard() {
  const backgroundImageUrl = 'Images/img7.jpg'; 

  return (
    <div
      className="gift-card-container my-5"
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      <div className="gift-card-content">
        <h5 className='h5-gift'>Purchase your Choice </h5>
<h2 className='h2-gift'>Improve your lifestile , purchase best for yoursel</h2>
        <p className='p-gift'></p>

<div className='my-5'>
<Link to="/blogs" className='btn-shop '>Read Blogs</Link>

</div>      </div>
    </div>
  );
}

export default GiftCard;
