import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './blogDetails.css'
function BlogDetails() {
  const { state } = useLocation(); // Get the state from the router
  const { image, title, blogContent } = state || {}; // Destructure the image, title, and blogContent from state

  // Log all the data received from the previous component
  useEffect(() => {
    console.log('Blog Details Data:', { image, title, blogContent });
  }, [image, title, blogContent]);

  // If the image or title is not available, show an error message
  if (!image || !title || !blogContent) {
    return <p>Blog not found!</p>;
  }

  return (
    <div className="container">
      <div className="blog-details-container mrg-top">
        <h1>{title}</h1> {/* Display the title */}
        <img
          src={image} // Use the image path passed from Blogs
          alt={title}
          className="blog-details-image"
          style={{
            width: '100%',           // Make image responsive
            height: '600px',         // Maintain aspect ratio
            borderRadius: '8px',    // Rounded corners for the image
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow
            marginBottom: '20px',    // Add space below the image
          }}
        />
        <div className="blog-content">
          <p className='p-blogdetails'>{blogContent}</p> {/* Display blog content */}
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
