import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CheckoutForm.css'; // Assuming custom CSS if needed

const CheckoutForm = () => {
  const location = useLocation();
  const { cartItems } = location.state; // Receive the cart items passed from Cart component

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Simulate order submission
    toast.success('Your order has been confirmed!', {
      position: 'top-right',
      autoClose: 3000,
    });

    // Optionally, clear the cart after submission
    localStorage.removeItem('cart');
  };

  return (
    <div className=" container container-form my-5">
      <h1 className="text-center h1-form mb-4">Checkout</h1>
     <div className="row">
     <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
        <div className="row justify-content-center w-100">
          {/* Email */}
          <div className="col-md-6 mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" required />
          </div>

          {/* Name */}
          <div className="col-md-6 mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input type="text" className="form-control" id="name" required />
          </div>

          {/* Address */}
          <div className="col-md-6 mb-3">
            <label htmlFor="address" className="form-label">Address</label>
            <input type="text" className="form-control" id="address" required />
          </div>

          {/* City */}
          <div className="col-md-6 mb-3">
            <label htmlFor="city" className="form-label">City</label>
            <input type="text" className="form-control" id="city" required />
          </div>

          {/* Country */}
          <div className="col-md-6 mb-3">
            <label htmlFor="country" className="form-label">Country</label>
            <input type="text" className="form-control" id="country" required />
          </div>

          {/* Postal Code */}
          <div className="col-md-6 mb-3">
            <label htmlFor="postalCode" className="form-label">Postal Code</label>
            <input type="text" className="form-control" id="postalCode" required />
          </div>
        </div>

        {/* Order Summary */}
        <div className="my-4 text-center">
          <h4 className='h4-form'>Your Order:</h4>
          {cartItems.map((item, index) => (
            <div key={index} className="mb-2">
              <p>
                {item.quantity} x {item.product.name} - ${item.totalPrice}
              </p>
            </div>
          ))}
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary mt-3">
          Submit Order
        </button>
      </form>
     </div>

      <ToastContainer /> {/* Toast notification container */}
    </div>
  );
};

export default CheckoutForm;
