import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import './BlogForm.css';

function BlogUploadForm() {
  const [title, setTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      // Add new blog post to Firestore
      await addDoc(collection(db, 'blogs'), {
        title,
        blogContent, // Storing blog content as Markdown
        createdAt: new Date(),
      });

      // Clear the form fields
      setTitle('');
      setBlogContent('');
    } catch (error) {
      console.error("Error uploading blog: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <form className="frm" onSubmit={handleSubmit}>
      <input 
        type="text" 
        placeholder="Blog Title" 
        value={title} 
        onChange={(e) => setTitle(e.target.value)} 
        required 
      />
      <textarea 
        placeholder="Blog Content (Markdown supported)" 
        value={blogContent} 
        onChange={(e) => setBlogContent(e.target.value)} 
        required 
      />
      <button type="submit" disabled={uploading} className="btn-shop">
        {uploading ? "Uploading..." : "Upload Blog"}
      </button>
    </form>
  );
}

export default BlogUploadForm;
