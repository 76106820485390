// src/components/BlogList.js

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './BlogList.css'
const BlogList = React.memo(() => {
  const blogs = [
    {
      id: 1,
      title: "A Detailed Guide on Choosing the Right Boots: How to Do It Right ",
      image: "Images/img1.jpg", 
      link: "/blog1",
    },
    {
      id: 2,
      title: "How to Buy the Perfect Stylish and Comfortable Trouser",
      image: "Images/img2.jpg", 

      link: "/blog2",
    },
   
    
  ];

  const navigate = useNavigate();

  return (
    <div className="container mrg-top">
      <div className="row">
        <div className="blog-list">
          {blogs.map(blog => (
            <div 
              key={blog.id} 
              className="blog-card" 
              onClick={() => navigate(blog.link)}
            >
              <img src={blog.image} alt={blog.title} />
<div className='title-height'>
<h2>{blog.title}</h2>

</div>            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default BlogList;
