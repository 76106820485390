import React from 'react';
import './HeroSection.css';

// Define an object for the image
const heroImage = {
  url: 'Images/Hero.jpg', // Add your image path here
};

function HeroSection() {
  const backgroundImageStyle = {
    backgroundImage: `url(${heroImage.url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // height: '100vh',
    position: 'relative',
  };

  return (
    <section className="hero-section" style={backgroundImageStyle}>

      <div className="overlay"></div>
      <div className="container content">
        <div className="row">
          <div className="col-sm-12 text-center my-5">
            <h1 className=" mb-3 h1-hero my-5 ">Find the best, buy the best
</h1>
            <h3 className=" text-white mb-4 my-3">
            a go-to guide to purchase product that suits you most

            </h3>
          <div className='my-5'>
          <a href="/blogs" className="btn-shop">
             Read Blogs
            </a>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
