import React from 'react'
import HeroSection from '../../HeroSection/Index'
import NewStock from '../../NewStock/Index'
import OurStory from '../../OurStory/Index'
import CustomersSay from '../../CustomersSay/Index'
import GiftCard from '../../GiftCard/Index'
import LawComponent from '../../Law/Index'


function Home() {
  return (
    <div>

      <HeroSection/>
   {/* <LawComponent/> */}
      {/* <NewStock/> */}
      <OurStory/>
      <CustomersSay/>
      <GiftCard/>
    </div>
  )
}

export default Home
